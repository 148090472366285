<template>
  <div class="container_con" style="background: none;">
    <!-- <el-card style="margin-bottom: 15px;" v-if="data">
      <div class="con_title"><span></span> 销售计划单详情</div>
      <div style="line-height: 24px; font-size: 14px;">
          <el-row :gutter="20" style="margin-bottom: 10px;">
              <el-col :span="6">开票日期：{{data.sale_plan.sale_plan_date}}</el-col>
              <el-col :span="6">开票人：{{data.sale_plan_admin_name}}</el-col>
              <el-col :span="6">部门：{{data.sale_plan_department_name}}</el-col>
              <el-col :span="6">销售单位：{{data.sale_plan.merchant.merchant_name}}</el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="6">单据编号：{{data.sale_plan.sale_plan_no}}</el-col>
              <el-col :span="6">单位编号：{{data.sale_plan.merchant.merchant_no}}</el-col>
          </el-row>
      </div>
    </el-card> -->

    <el-card style="margin-bottom: 15px; display: none;" v-if="data">
      <div class="con_title"><span></span> 销售出库单详情</div>
      <div style="line-height: 24px; font-size: 14px;">
        <el-row :gutter="20" style="margin-bottom: 10px;">
          <el-col :span="4">开票日期：{{ data.sale_out_date }}</el-col>
          <el-col :span="5">单据编号：{{ data.sale_out_no }}</el-col>
          <el-col :span="5">开票人：{{ data.admin_name }}</el-col>
          <el-col :span="5">部门：{{ data.department_name }}</el-col>
          <el-col :span="5">客户名称：{{ data.sale_plan.merchant.merchant_name }}</el-col>
        </el-row>
      </div>
      <div class="" style="margin-top: 10px; font-size: 14px;">
        <div style="padding-bottom: 20px;">出库产品：</div>
        <div class="">
          <el-table :data="data.sale_out_product" style="width: 100%">
            <el-table-column label="产品编号">
              <template v-slot="scope">
                {{ JSON.parse(scope.row.sale_plan_product.product).product_no }}
              </template>
            </el-table-column>
            <el-table-column label="品名">
              <template v-slot="scope">
                {{ JSON.parse(scope.row.sale_plan_product.product).product_name }}
              </template>
            </el-table-column>
            <el-table-column label="型号规格">
              <template v-slot="scope">
                {{ JSON.parse(scope.row.sale_plan_product.product).model }}
              </template>
            </el-table-column>
            <el-table-column label="生产厂家">
              <template v-slot="scope">
                {{ JSON.parse(scope.row.sale_plan_product.product).manufacturer }}
              </template>
            </el-table-column>
            <el-table-column label="包装单位">
              <template v-slot="scope">
                {{ scope.row.pack_unit_name }}
              </template>
            </el-table-column>
            <el-table-column label="数量">
              <template v-slot="scope">
                {{ scope.row.product_num }}
              </template>
            </el-table-column>
            <el-table-column label="单价">
              <template v-slot="scope">
                {{ scope.row.product_price }}
              </template>
            </el-table-column>
            <el-table-column label="金额">
              <template v-slot="scope">
                <span style="color: red;">{{ scope.row.amount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="批号/生产日期">
              <template v-slot="scope">
                {{ scope.row.batch_number }}
              </template>
            </el-table-column>
            <el-table-column label="有效期至">
              <template v-slot="scope">
                {{ scope.row.valid_until || '长效期' }}
              </template>
            </el-table-column>
            <el-table-column label="注册证名">
              <template v-slot="scope">
                {{ JSON.parse(scope.row.sale_plan_product.product).register_cert_name }}
              </template>
            </el-table-column>
            <el-table-column label="注册证号">
              <template v-slot="scope">
                {{ JSON.parse(scope.row.sale_plan_product.product).register_cert_no }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
    <el-card style="margin-bottom: 15px;" v-if="data">
      <div class="con_title"><span></span> 退回信息</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="单据编号前缀" prop="no">
          <el-select v-model="form.no" placeholder="请选择" style="width: 300px; margin-right: 15px;" filterable>
            <el-option :label="item.value + '__' + item.title" v-for="(item, index) in zjlist" :key="index"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库日期：" prop="sale_return_date">
          <el-date-picker v-model="form.sale_return_date" type="date" value-format="YYYY-MM-DD" placeholder="选择日期"
            style="width:300px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="部门：" prop="department_id">
          <el-cascader style="width:300px" v-model="form.department_id" :options="list"
            :props="{ expandTrigger: 'hover', checkStrictly: true, emitPath: false, value: 'id', label: 'title', children: '_child' }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="业务员:" prop="admin_id">
          <el-select v-model="form.admin_id" placeholder="请选择" style="width:300px" filterable>
            <el-option :label="item.full_name + '(' + item.department_name + ')' + '-' + item.mnemonic_code"
              v-for="(item, index) in rylist" :key="index" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" style="width:300px" />
        </el-form-item>
        <el-form-item label="退单类型：">
          <el-radio-group v-model="form.type">
            <el-radio label="1">整单退单</el-radio>
            <el-radio label="2">部分退单</el-radio>
            <el-radio label="3">整单退货到销售计划单</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-table :data="have_sele_arr" ref="tables" style="width: 100%" @selection-change="handleSelectionChange"
          @sort-change="handleSortChange">
          <el-table-column type="selection" width="55" align="center" v-if="form.type == 2" />
          <el-table-column label="产品编号" sortable :sort-method="formatterproduct_no">
            <template v-slot="scope">
              {{ JSON.parse(scope.row.sale_plan_product.product).product_no }}
            </template>
          </el-table-column>
          <el-table-column label="品名" sortable :sort-method="formatterproduct_name">
            <template v-slot="scope">
              {{ JSON.parse(scope.row.sale_plan_product.product).product_name }}
            </template>
          </el-table-column>
          <el-table-column label="型号规格">
            <template v-slot="scope">
              {{ JSON.parse(scope.row.sale_plan_product.product).model }}
            </template>
          </el-table-column>
          <el-table-column label="生产厂家">
            <template v-slot="scope">
              {{ JSON.parse(scope.row.sale_plan_product.product).manufacturer }}
            </template>
          </el-table-column>
          <el-table-column label="包装单位">
            <template v-slot="scope">
              {{ scope.row.pack_unit_name }}
            </template>
          </el-table-column>
          <el-table-column label="数量" width="150" sortable :sort-method="formatterproduct_num">
            <template v-slot="scope">
              <div>
                <el-input-number v-if="form.type == 2" v-model="scope.row.product_num" controls-position="right"
                  style="width: 100%;" :min="0" size="small"></el-input-number>
                <span v-if="form.type != 2">{{ scope.row.product_num }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="单价" width="150">
            <template v-slot="scope">
              {{ scope.row.sale_price }}
            </template>
          </el-table-column>
          <el-table-column label="金额" sortable :sort-method="formattertotal">
            <template v-slot="scope">
              <span style="color: red;">{{ (parseFloat(scope.row.product_num) * parseFloat(scope.row.sale_price) ||
                0).toFixed(4) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="批号/生产日期" width="120">
            <template v-slot="scope">
              {{ scope.row.batch_number }}
            </template>
          </el-table-column>
          <el-table-column label="有效期至" width="190">
            <template v-slot="scope">
              {{ scope.row.valid_until || '长效期' }}
            </template>
          </el-table-column>
          <el-table-column label="注册证名">
            <template v-slot="scope">
              {{ JSON.parse(scope.row.sale_plan_product.product).register_cert_name }}
            </template>
          </el-table-column>
          <el-table-column label="注册证号">
            <template v-slot="scope">
              {{ JSON.parse(scope.row.sale_plan_product.product).register_cert_no }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="50" v-if="form.type == 2">
            <template v-slot="scope">
              <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addpurchaseAccept",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
        type: '1'
      },
      rules: {
        sale_return_date: [
          { required: true, message: "退回日期不能为空", trigger: "blur" }
        ],
        department_id: [
          { required: true, message: "部门不能为空", trigger: "blur" }
        ],
        no: [
          { required: true, message: "编号前缀不能为空", trigger: "blur" }
        ],
        admin_id: [
          { required: true, message: "业务员不能为空", trigger: "blur" }
        ],
      },
      zjlist: [],
      rylist: [],
      hwlist: [],
      list: [],
      data: '',
      have_sele_arr: [],

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      shortcuts: [{
        text: '2099-12-31',
        value: '2099-12-31'
      }, {
        text: '长效期',
        value: 'null'
      }],
      sortedData: []
    };
  },
  created() {
    this.id = this.$route.query.id || '';
    this.get_data();
    this.form.sale_return_date = this.today_val()
    this.getOptions()
  },
  methods: {
    handleSortChange({ column, prop, order }) {
      // 获取排序后的数组
      const sortedData = this.$refs.tables.store.states.data;
      this.sortedData = sortedData
    },
    formatterproduct_no(a, b) {
      return JSON.parse(a.sale_plan_product.product).product_no.localeCompare(JSON.parse(b.sale_plan_product.product).product_no)
    },
    formatterproduct_name(a, b) {
      return JSON.parse(a.sale_plan_product.product).product_name.localeCompare(JSON.parse(b.sale_plan_product.product).product_name)
    },
    formatterproduct_num(a, b) {
      return Number(a.product_num) - Number(b.product_num);
    },
    formattertotal(a, b) {
      return Number((parseFloat(a.product_num) * parseFloat(a.sale_price) || 0).toFixed(4)) - Number((parseFloat(b.product_num) * parseFloat(b.sale_price) || 0).toFixed(4));
    },
    handleDelete(index) {
      const _this = this;
      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this.have_sele_arr.splice(index, 1)
      })
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 提交按钮 */
    submitForm: function (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {

          if (form == 'form') {
            let _data = { ...this.form };
            _data.sale_out_id = this.id;
            if (_data.type == 2) {
              let proarr = [];
              if (this.ids.length == 0) {
                this.$message.error('请选择退单产品');
                return false;
              }
              if (this.sortedData.length == 0) {
                for (let i = 0; i < this.have_sele_arr.length; i++) {
                  let _item = this.have_sele_arr[i];
                  if (this.ids.indexOf(_item.id) != -1) {
                    if (!_item.product_num) {
                      this.$message.error('请完善退单信息');
                      return false;
                    } else {
                      proarr.push({
                        sale_out_product_id: _item.id,
                        product_num: _item.product_num,
                        // batch_number:_item.batch_number,
                        // valid_until:_item.valid_until=='Invalid Date'?null:(_item.valid_until || null),
                        // product_price:_item.product_price,
                        amount: parseFloat(_item.product_num) * parseFloat(_item.sale_price) || 0
                      })
                    }
                  }
                }
              } else {
                for (let i = 0; i < this.sortedData.length; i++) {
                  let _item = this.sortedData[i];
                  if (this.ids.indexOf(_item.id) != -1) {
                    if (!_item.product_num) {
                      this.$message.error('请完善退单信息');
                      return false;
                    } else {
                      proarr.push({
                        sale_out_product_id: _item.id,
                        product_num: _item.product_num,
                        // batch_number:_item.batch_number,
                        // valid_until:_item.valid_until=='Invalid Date'?null:(_item.valid_until || null),
                        // product_price:_item.product_price,
                        amount: parseFloat(_item.product_num) * parseFloat(_item.sale_price) || 0
                      })
                    }
                  }
                }
              }
              _data.sale_out_array = proarr
            }
            this.is_ajax = true;
            this.$httpPost("/backend/SaleReturn/save", _data).then((res) => {
              if (res.status == 200) {
                this.$message.success('新增成功');
                this.$router.go(-1)
              } else {
                this.$message.error(res.message);
              }
              this.is_ajax = false;
            }).catch((err) => {
              console.log(err);
              this.is_ajax = false;
            });
          }
        }
      });
    },
    get_data() {
      this.$httpGet("/backend/SaleOut/read", { id: this.id }).then((res) => {
        if (res.status == 200) {
          this.data = res.data;
          let arr = res.data.sale_out_product;
          for (let i = 0; i < arr.length; i++) {
            arr[i].product_price = parseFloat(arr[i].product_price)
          }
          this.have_sele_arr = [...arr];
          this.form.admin_id = res.data.admin_id;
          this.form.department_id = res.data.department_id
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    getOptions() {
      this.$httpGet("/backend/SysConfig/index", {
        size: 10000000,
        groups: '销售退回'
      }).then((res) => {
        if (res.status == 200) {
          this.zjlist = res.data.data;
          if (this.zjlist.length > 0) {
            this.form.no = this.zjlist[0].value
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
      this.$httpGet("/backend/sys.Admin/assistData", {}).then((res) => {
        if (res.status == 200) {
          this.list = res.data.department_list;
          // if(!this.id){
          //     for(let i=0;i<this.list.length;i++){
          //         if(this.list[i].title=='销售部'){
          //             this.form.department_id=this.list[i].id
          //             break;
          //         }
          //     }
          // }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
      this.$httpGet("/backend/sys.Admin/salesmanList", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.rylist = res.data.data;
          // if(this.rylist.length>0){
          //     this.form.admin_id=this.rylist[0].id
          // }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;

  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }

  .el-table {
    margin: 30px 0;
  }
}

.top_box {
  display: flex;
  justify-content: space-around;
}

.examin {
  display: flex;
  justify-content: space-between;
}
</style>
